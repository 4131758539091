:root {
    --primary: #643D72;
    --cream: #FFFDEB;
    --dark-purple: #6B4876;
    --light-purple: #B772CC;
    --mint: #CDFFEF;
    --pink: #F2D5FF
}

body {
    color: var(--primary);
    /*overflow-x: hidden !important;*/
    -webkit-overflow-scrolling: touch;
}


@font-face {
    font-family: 'keep_on_truckinregular';
    src: url('../fonts/keepontruckin-webfont.woff2') format('woff2'),
    url('../fonts/keepontruckin-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'calistogaregular';
    src: url('../fonts/jc_calistoga-webfont.woff2') format('woff2'),
    url('../fonts/jc_calistoga-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}


@font-face {
    font-family: 'cooper_blackregular';
    src: url('../fonts/cooper_black_regular-webfont.woff2') format('woff2'),
    url('../fonts/cooper_black_regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}


p {
    margin: 0
}

.font-1 {
    font-family: 'calistogaregular', regular;
}

.font-2 {
    font-family: 'Montserrat', sans-serif;
    line-height: 22px;
}

.font-3 {
    font-family: 'cooper_blackregular', regular
}

.font-4 {
    font-family: 'Roboto Mono', monospace;
}


.splash-subtext {
    font-size: 67px;
    text-align: center;
}

.splash-subtext-mobile {
    font-size: 48px;
    text-align: center;
}

.splash-subtext2 {
    font-size: 46px;
    text-align: center;
}

.splash-subtext3 {
    font-size: 22px;
    text-align: center;
}

.splash-subtext2-mobile {
    font-size: 28px;
    text-align: center;
}

.pointer {
    cursor: pointer;
}

.footer-titles {
    font-size: 56px;
}

.footer-titles-mobile {
    font-size: 40px;
}

.mobile-nav-item {
    font-size: 32px;
    color: var(--cream);
}

.splash-bg {
    background: linear-gradient(to bottom, #9558C9, #E9CDE0, #F1D7E2, #CCFFEF);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.splash-bg-mobile {
    background: linear-gradient(to bottom, #965ACA, #D0AAD9, #F1D7E2, #DAF8EC);
    display: flex;
    flex-direction: column;
    align-items: center;
}


.cream-bg {
    background-color: var(--cream);
}

.bg-dark-purple {
    background-color: var(--dark-purple);
}

.bg-light-purple {
    background-color: var(--light-purple);
}

.cream {
    color: var(--cream)
}

.footer-divider {
    height: 300px;
    width: 3px;
}

.footer-divider-mobile {
    height: 2px;
    width: 70%;
}

.splash-button {
    font-size: 26px;
    height: var(--space64);
}

.flex-1 {
    flex: 1
}

button {
    background-color: var(--cream) !important;
    outline: none !important;
    border: none !important;
    border-radius: var(--space40);
    height: var(--space56);
    padding: 0 var(--space24);
    font-size: 24px;
    font-weight: bold;
    color: var(--primary);
    box-shadow: inset 0px 3px 6px #00000029, 0px 6px 10px #00000029;
    transition: 150ms ease;
    cursor: pointer;
}

button:hover {
    transform: translateY(-4px);
    box-shadow: inset 0px 3px 6px #00000039, 0px 6px 12px #00000039;
}

.member-name {
    font-size: 24px;
    margin: var(--space24) 0 var(--space12) 0;
    font-weight: bold;
}

.page-break1 {
    left: 0;
    right: 0;
    height: 80px;
    position: absolute;
    z-index: 1;
}

.bold {
    font-weight: bold;
}

.member-title {
    font-size: 20px;
}

.bg-mint {
    background-color: var(--mint);
}

.bg-pink {
    background-color: var(--pink);
}

.sparkle1 {
    opacity: .9;
    position: absolute;
    left: 10%;
    top: 0;
    z-index: 0;
    width: 100px;
}

.sparkle2 {
    opacity: .9;
    position: absolute;
    left: 6%;
    top: 22%;
    z-index: 0;
    width: 50px;
}

.sparkle3 {
    opacity: .9;
    position: absolute;
    right: 10%;
    top: 0;
    z-index: 0;
    width: 100px;
    transform: scaleX(-1);
}

.sparkle4 {
    opacity: .9;
    position: absolute;
    right: 6%;
    top: 22%;
    z-index: 0;
    width: 50px;
    transform: scaleX(-1);
}

.sparkle5 {
    opacity: .9;
    position: absolute;
    left: -400px;
    top: 62%;
    z-index: 0;
    width: 50px;
    transform: scaleX(-1);
}

.sparkle6 {
    opacity: .9;
    position: absolute;
    left: 400px;
    top: 90px;
    z-index: 0;
    width: 50px;
    transform: scaleX(-1) scale(.8);
}

.sparkle7 {
    opacity: .9;
    position: absolute;
    left: 600px;
    top: -10px;
    z-index: 0;
    width: 50px;
    transform: scaleX(-1) scale(.8);
}

.sparkle8 {
    opacity: .9;
    position: absolute;
    right: -500px;
    top: 210px;
    z-index: 0;
    width: 50px;
    transform: scaleX(-1) scale(.6);
}

.sparkle9 {
    opacity: .9;
    position: absolute;
    right: -400px;
    top: 110px;
    z-index: 0;
    width: 50px;
    transform: scaleX(-1) scale(.3);
}

.sparkle10 {
    opacity: .9;
    position: absolute;
    right: 800px;
    top: 210px;
    z-index: 0;
    width: 50px;
    transform: scaleX(-1) scale(.3);
}

.sparkle11 {
    opacity: .9;
    position: absolute;
    right: 500px;
    top: 80px;
    z-index: 0;
    width: 50px;
    transform: scaleX(-1) scale(.6);
}


.sparkle1-mobile {
    opacity: .9;
    position: absolute;
    left: 0%;
    top: 0%;
    z-index: 0;
    width: 50px;
    transform: scaleX(1) scale(.5);
}

.sparkle2-mobile {
    opacity: .9;
    position: absolute;
    left: 5%;
    top: 0%;
    z-index: 0;
    width: 25px;
    transform: scaleX(1) scale(.5);
}

.sparkle3-mobile {
    opacity: .9;
    position: absolute;
    right: 0%;
    top: -0%;
    z-index: 0;
    width: 50px;
    transform: scaleX(-1) scale(.5);
}

.sparkle4-mobile {
    opacity: .9;
    position: absolute;
    right: 5%;
    top: 0%;
    z-index: 0;
    width: 25px;
    transform: scaleX(-1) scale(.5);
}

.sparkle5-mobile {
    opacity: .9;
    position: absolute;
    right: -25px;
    top: -60px;
    z-index: 0;
    width: 25px;
    transform: scaleX(-1);
}

.sparkle6-mobile {
    opacity: .9;
    position: absolute;
    right: -50px;
    top: 0px;
    z-index: 0;
    width: 25px;
    transform: scaleX(-1.2);
}

.sparkle7-mobile {
    opacity: .9;
    position: absolute;
    right: -5px;
    top: 100px;
    z-index: 0;
    width: 25px;
    transform: scaleX(-1.2);
}

.sparkle8-mobile {
    opacity: .9;
    position: absolute;
    left: -25px;
    top: -60px;
    z-index: 0;
    width: 25px;
    transform: scaleX(-1);
}

.sparkle9-mobile {
    opacity: .9;
    position: absolute;
    left: -50px;
    top: 0px;
    z-index: 0;
    width: 25px;
    transform: scaleX(-1.2);
}

.sparkle10-mobile {
    opacity: .9;
    position: absolute;
    left: -5px;
    top: 100px;
    z-index: 0;
    width: 25px;
    transform: scaleX(-1.2);
}

.sparkle11-mobile {
    opacity: .9;
    position: absolute;
    left: -35px;
    top: -10px;
    z-index: 0;
    width: 25px;
    transform: scaleX(-1.2);
}

.sparkle12-mobile {
    opacity: .9;
    position: absolute;
    left: 65px;
    top: -50px;
    z-index: 0;
    width: 25px;
    transform: scaleX(-1.2);
}

.sparkle13-mobile {
    opacity: .9;
    position: absolute;
    left: 160px;
    top: -67px;
    z-index: 0;
    width: 25px;
    transform: scaleX(-1.2);
}

.sparkle14-mobile {
    opacity: .9;
    position: absolute;
    right: -10px;
    top: -27px;
    z-index: 0;
    width: 25px;
    transform: scaleX(-1.2);
}

.sparkle15-mobile {
    opacity: .9;
    position: absolute;
    right: 50px;
    top: -127px;
    z-index: 0;
    width: 25px;
    transform: scaleX(1.2) scale(.8);
}

.sparkle16-mobile {
    opacity: .9;
    position: absolute;
    right: 160px;
    top: -147px;
    z-index: 0;
    width: 25px;
    transform: scaleX(1.2) scale(.8);
}


.pink-grass {
    position: absolute;
    bottom: 50px
}

.purple-grass {
    position: absolute;
    bottom: -30px
}

.firework-1 {
    position: absolute;
    top: 10%;
    left: 28%;
    width: 150px;
}

.firework-2 {
    position: absolute;
    top: -50px;
    right: 450px;
    width: 250px;
}

.plunge-bg {
    background: linear-gradient(to bottom, #2B272E, #55346E);
    min-height: 100vh;
}

.salmon {
    color: #FF8662
}

.pink-button {
    background-color: #FFBCE8 !important;
}

.pointer {
    cursor: pointer !important;
}

.hover {
    transition: 100ms ease;
}

.hover:hover {
    transform: translateY(-4px);
}

.white-on-h-text {
    color: #FFFFFF;
    opacity:.5;
    transition: 150ms ease;
}

.white-on-h-text:hover {
    color: #FFFFFF;
    opacity: 1;
}

.white-on-h:hover {
    color: #FFFFFF
}

.opacity-transition {
    transition: opacity 1200ms ease-in-out;
}

.plunge-animation-cover {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to bottom, transparent, #000000C3);
    z-index: 999;
}

.plunge-animation-cover-dark {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    animation: darken 12s ease forwards;
    background-color: #00000000;
}

@keyframes darken {
    0% {
        transform: translate3d(0, 0, 0);
        background-color: #00000000;
    }
    100% {
        background-color: #2f2034;
    }
}


.before-shake {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    animation-iteration-count: infinite;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
    opacity: 0
}

@keyframes shake {
    0% {
        transform: translate3d(0, 0, 0);
    }
    10%, 90% {
        transform: translate3d(-1px, 0, 0);
    }

    20%, 80% {
        transform: translate3d(2px, 0, 0);
    }

    30%, 50%, 70% {
        transform: translate3d(-4px, 0, 0);
    }

    40%, 60% {
        transform: translate3d(4px, 0, 0);
    }
    100% {
        transform: translate3d(0, 0, 0);
    }
}

.shrink {
    animation: shrink 4s cubic-bezier(.36, .07, .19, .97) forwards;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
    transform: scale(1) rotate(0deg);
}

@keyframes shrink {
    0% {
        transform: scale(1) rotate(0deg);
    }
    10% {
        transform: scale(1.2);
        opacity: 1;
    }
    80% {
        transform: scale(.5);
    }
    90% {
        transform: scale(0) rotate(10deg);
        opacity: 0;
    }
}

@keyframes shrink2 {
    20% {
        transform: translate3d(2px, 0, 0) scale(1);
        opacity: 1;
    }

    30% {
        transform: translate3d(-4px, 0, 0);
    }

    40% {
        transform: translate3d(4px, 0, 0);
    }
    50% {
        transform: translate3d(-4px, 0, 0);
    }

    60% {
        transform: translate3d(4px, 0, 0);
    }

    70% {
        transform: translate3d(-4px, 0, 0);
    }

    80% {
        transform: translate3d(2px, 0, 0);
    }

    90% {
        transform: translate3d(-1px, 0, 0) scale(0);
    }
    100% {
        transform: scale(0);
        opacity: 0;
    }
}


.Modal {
    background-color: #FFFDEB;
    padding: var(--space32);
    border-radius: var(--space20);
}

.Modal2 {
    background-color: #B772CC;
    padding: var(--space32);
    border-radius: var(--space20);
    color: var(--cream);
}

.Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #00000080;
    z-index: 9999999;
}

.wallet-option {
    display: flex;
    flex-direction: column;
    margin: var(--space32);
    align-items: center;
    cursor: pointer;
    transition: 150ms ease;
}

.wallet-option:hover {
    color: #B772CC;
    transform: translateY(-2px);
}

.wallet-option:hover p {
    color: #B772CC;
}

.close-modal-x {
    cursor: pointer;
    position: absolute;
    top: var(--space16);
    right: var(--space16);
}

.mint-item {
    box-shadow: 0 0 6px #00000010;
    transition: 150ms ease;
}

.select-item {
    box-shadow: 0 0 6px #00000010;
    transition: 150ms ease;
    border: 2px solid transparent;
    border-radius: 4px;
    cursor: pointer;
}

.select-item-selected {
    box-shadow: 0 0 6px #00000010;
    transition: 150ms ease;
    border: 2px solid #643D72;
    border-radius: 4px;
}

.select-item:hover {
    transform: translateY(-4px);
    box-shadow: 0 4px 10px #00000020;
}


.flex-wrap {
    flex-wrap: wrap;
}

.mint-item:hover {
    transform: translateY(-4px);
    box-shadow: 0 4px 10px #00000020;
}

.spinner {
    height: 24px;
    width: 24px;
    border: 2px solid #FFFFFF20;
    margin-left: auto;
    margin-right: auto;
    border-radius: 50%;
    border-left-color: #643D72;
    animation: rotate 1.2s linear infinite;
    box-sizing: border-box;
}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}
